<template >
    <template v-if="dsMeeting.current">
        <div style="background-color:#F5F5F5" class="p-2 border rounded">
            <div class="mb-1">
                <div class="row ">
                    <div class="d-flex justify-content-start">
                        <h6 v-if="vHasMeeting" class="mb-0"><a target="_blank" :href="`/nt/scope-items/scope-item?ID=${dsMeeting.current.Meeting_ID}`">Site Walk Meeting: {{ dsMeeting.current.Meeting_ID }}</a></h6>
                        <button v-if="!vHasMeeting" type="button" class="btn btn-primary" @click="createSiteWalkMeeting" :disabled="vWorking">
                            <span v-if="vWorking" class="spinner-border spinner-border-sm me-1"></span>{{$t('Creating Site Walk Meeting')}}
                        </button>
                    </div>
                </div>
            </div>  
        </div>
    </template>
</template>

<script setup>
    import { ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { defineProps } from 'vue';
    
    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean
    });

    const vHasMeeting = ref(false), vWorking = ref(false), vMeetingID = ref(null);

    const procCreateMeeting = getOrCreateProcedure({ id:"procCreateMeeting", procedureName:"astp_MSFT_MPAP_CreateMeeting" });
    const procGetPOID = getOrCreateProcedure({ id:"procGetPOID", procedureName:"astp_MSFT_MPAP_GetPOID" });


    procGetPOID.execute({Item_ID: props.itemId }).then((pData)=>{
        dsMeeting.recordSource.whereClause = "Item_ID = " + props.itemId;
        dsMeeting.load().then(() => { 
            if(dsMeeting.current?.Meeting_ID !== undefined){
                vHasMeeting.value = true;
            } else {
                vHasMeeting.value = false;
            }
            console.log("Meeting ID:", dsMeeting.current?.Meeting_ID); 
        });
    }).catch((pErr)=>{
        
    });

    const dsMeeting = getOrCreateDataObject(
    {
        "id": "dsMeeting",
        "viewName": "aviw_MSFT_MPAP_MeetingID",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  false,
        "allowInsert":  false,
        "allowDelete":  false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Item_ID"},
            {"name": "Meeting_ID"},
            {"name": "Title"},      
        ],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const createSiteWalkMeeting = async() => {
        vWorking.value = true;

        procCreateMeeting.execute({
            Item_ID: props.itemId
        }).then((res)=>{
            if(res.Table[0].Meeting_ID == null){
                vHasMeeting.value = false;
            } else {
                vHasMeeting.value = true;
                vMeetingID.value = res.Table[0].ID;
                dsMeeting.load();
            }

            vWorking.value = false;
        });
    }
</script>